import router from '@/router/index';
import Vue from 'vue';

export default {
    /**
     * Get list of TDM Roles
     */
    async getAllGroups() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/listOfGroups`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/listOfGroups`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of roles." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllRoles() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/listRoles`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/listRoles`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of roles." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async listUserRoles(username) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/listUserRoles`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/listUserRoles`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            username: username


        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of users." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getUsers(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `tdm/user/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            firstName: query.firstName ?? '',
            lastName: query.lastName ?? '',
            username: query.username ?? '',
            mobileNumber: query.mobileNumber ?? '',
            dateFrom: query.dateFrom ?? '',
            dateTo: query.dateTo ?? '',
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of users." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createUser(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/create`
        }
        const user = `${formData.username}:${formData.password}`
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'user': `${btoa(user)}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            firstName: `${formData.firstName}`,
            lastName: `${formData.lastName}`,
            email: `${formData.email}`,
            mobileNumber: `${formData.mobileNumber}`,
            remarks: `${formData.remarks}`,
            role: `${formData.role}`,
            isEnabled: `${formData.isEnabled}`,
            paymentAgent: formData.paymentAgent ? formData.paymentAgent.code ?? null : null
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async updateUser(formData) {

        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/update`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            username: `${formData.username}`,
            firstName: `${formData.firstName}`,
            lastName: `${formData.lastName}`,
            email: `${formData.email}`,
            mobileNumber: `${formData.mobileNumber}`,
            remarks: `${formData.remarks}`,
            role: `${formData.role}`,
            isEnabled: `${formData.isEnabled}`,
            paymentAgent: formData.paymentAgent ? formData.paymentAgent.code ?? null : null
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
     * Update Account User's Password
     * parameters: JSON Object containing:
     * oldPassword: string
     * newPassword: string
     * username: string
     */
    async updateAccountPassword(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/userManagement/updatePassword`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/userManagement/updatePassword`
        }
        const user = `${parameters.username}:${parameters.oldPassword}:${parameters.newPassword}`
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'user': `${btoa(user)}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {}
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating your password." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            if (error && error.response && error.response.data) {
                console.log(error.response)
                if (error.response.data.message) {
                    return { error: error.response.data.message };
                } else {
                    return { error: error.response.data };
                }
            } else {
                return { error: "Error: " + error + "" };
            }
        }
    },

    /**
     * Get total amounts and transaction count of transactions per day for Merchant Users
     */
    async getMerchantTransactionsTotal(limit = 10, offset = 0, merchantId) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/dashboard/merchantTotals`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/dashboard/merchantTotals`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit,
            merchantID: merchantId
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * merchantTotals - JSON array of transaction objects
                 * transaction object contains:
                 * businessDate - datestring; date the transactions are transacted (yyyy-MM-dd HH:mm:ss.S)
                 * lastUpdated - datestring; date the transaction object is last updated (yyyy-MM-dd HH:mm.ss.S)
                 * transactionAmount - int; total amount computed from the list of transactions per day
                 * dataName - string; name to be displayed for the data in the chart
                 * id - int; transaction object identifier
                 * transactionCount - int; total transaction count computed from the list of transactions per day
                 * merchantName - string
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
     * Get total amounts and transaction count of transactions per day for Admin Users
     */
    async getTransactionsTotal(limit = 10, offset = 0) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/dashboard/dashboardTotals`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/dashboard/dashboardTotals`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * dashboardTotals - JSON array of transaction objects
                 * transaction object contains:
                 * businessDate - datestring; date the transactions are transacted (yyyy-MM-dd HH:mm:ss.S)
                 * lastUpdated - datestring; date the transaction object is last updated (yyyy-MM-dd HH:mm.ss.S)
                 * transactionAmount - int; total amount computed from the list of transactions per day
                 * dataName - string; name to be displayed for the data in the chart
                 * id - int; transaction object identifier
                 * transactionCount - int; total transaction count computed from the list of transactions per day
                 * countAverage - decimal; average of transactionCount
                 * amountAverage - decimal; average of transactionAmount
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getDailyTerminalSummary() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/terminal/dailySummary`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/terminal/dailySummary`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        try {
            const response = await Vue.axios.post(url, {}, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of terminal summary." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getLatestUpdatedTerminal(offset, limit) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/terminal/getLatestUpdatedTerminal`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/terminal/getLatestUpdatedTerminal`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString()
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of terminals." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**
     * Get transaction totals per week
     */
    async getDashboardSummary() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/dashboard/summary`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/dashboard/summary`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        try {
            const response = await Vue.axios.post(url, {}, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string;
                 * statusCode string; http status code
                 * transactionTotalsPerType - JSON object containing:
                 * EXPIRED - JSON object containing:
                 * percentage: double; number of percentage of expired transactions/total transactions of the day
                 * count: int; number of expired transactions of the day
                 * PAID - JSON object containing:
                 * percentage: double; number of percentage of paid transactions/total transactions of the day
                 * count: int; number of paid transactions of the day
                 * PENDING - JSON object containing:
                 * percentage: double; number of percentage of pending transactions/total transactions of the day
                 * count: int; number of pending transactions of the day
                 * POSTED - JSON object containing:
                 * percentage: double; number of percentage of posted transactions/total transactions of the day
                 * count: int; number of posted transactions of the day
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of summaries." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getTerminals(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/terminal/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/terminal/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            terminalId: query.terminalId ?? '',
            serialNum: query.serialNum ?? '',
            merchantId: query.merchantId ?? '',
            merchantName: query.name ?? '',
            // storeAssociation: query.storeAssociation ?? '',

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of terminals." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getTickets(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/ticket/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/ticket/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            code: query.code ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of tickets." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createTerminal(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/terminal/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/terminal/create`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            terminalId: formData.terminalId,
            serialNum: formData.serialNum,
            model: formData.model,
            brandName: formData.brandName,
            tradeName: formData.tradeName,
            type: formData.type ?? "",
            merchantId: formData.merchant?.merchantId ?? null, // Correctly access the merchantId
            merchantName: formData.merchant?.name ?? "", // Correctly access the merchantName
            storeAssociation: formData.merchant?.storeAssociation ?? "",
            branch: formData.branch ? formData.branch.code ?? "" : "",
            deviceType: formData.deviceType ? formData.deviceType.code ?? "" : "",
            paymentAgent: formData.paymentAgent ? formData.paymentAgent.code ?? null : null,
            assignedTo: formData.user ? formData.user.username ?? "" : "",
            network: formData.network ?? "",
            location: formData.location ?? "",
            pandateEntryMode: formData.pandateEntryMode ?? "",
            currency: JSON.stringify(formData.currency) ?? "",
            posterminalType: formData.posterminalType ?? "",
            bancnetAddress: formData.bancnetAddress ?? "",
            bancnetFixedFee: formData.bancnetFixedFee ?? null,
            bancnetMerchantSettlementBankCode: formData.bancnetMerchantSettlementBankCode ?? "",
            bancnetTerminalOwner: formData.bancnetTerminalOwner ?? "",
            bancnetTerminalCode: formData.bancnetTerminalCode ?? "",
            maximumAmount: formData.maximumAmount ?? null,
            pinEntryCapability: formData.pinEntryCapability ?? "",
            posTerminalEntryCapability: formData.posTerminalEntryCapability ?? "",
            posConditionCode: formData.posConditionCode ?? "",
            bancnetConvenienceFee: formData.bancnetConvenienceFee ?? null,
            simNetwork: formData.simNetwork ?? "",
            simCardNumber: formData.simCardNumber ?? "",
            dateInstalled: formData.dateInstalled ? (formData.dateInstalled = undefined ? null : formData.dateInstalled) : null,
            installedBy: formData.installedBy ?? "",
            ipAddress: formData.ipAddress ?? "",
            status: formData.status ?? "",
            emvContact: formData.emvContact ?? "",
            cardNetworkUsed: formData.cardNetworkUsed ?? '',
            motopaymentIndicator: formData.motopaymentIndicator ?? '',
            unattendedAcceptanceTerminalIndicator: formData.unattendedAcceptanceTerminalIndicator ?? '',
            cardHolderidmethod: formData.cardHolderidmethod ?? '',
            serviceDevelopmentField: formData.serviceDevelopmentField ?? '',
            password: formData.terminalPassword ?? '',
            terminalMonitoring: formData.terminalMonitoring ?? '',
            autoSettlement: formData.autoSettlementFlag,
            autoSettlementTime: formData.autoSettlementTime ?? '',
            terminalPort: formData.terminalPortNumber ?? '',
            printSaleReceipt: formData.printSaleReceipt,
            mode: formData.terminalMode ?? '',
            mac: formData.terminalMacAddress ?? '',
            // merchants: JSON.stringify(formData.merchantAssociation) ?? {},
            batchIndex: formData.batchIndex ?? '',
            batchLimit: formData.batchLimit ?? '',
            batchNumber: formData.batchNumber ?? '',
            stan: formData.stan ?? '',
            cardSchemes: JSON.stringify(this.filterCardScheme(formData.cardScheme)) ?? {},
            emvs: JSON.stringify(this.filterEmv(formData.emv)) ?? {},
            isEnabled: formData.isEnabled,
            enableVoid: formData.enableVoid,
            enableBalanceInquiry: formData.enableBalanceInquiry,
            enableCashout: formData.enableCashout
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error.response.data.message };
        }
    },

    async updateTerminal(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/terminal/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/terminal/update`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            terminalId: formData.terminalId,
            serialNum: formData.serialNum,
            model: formData.model,
            brandName: formData.brandName,
            tradeName: formData.tradeName,
            type: formData.type ?? '',
            merchantId: formData.merchant?.merchantId ?? null, // Correctly access the merchantId
            merchantName: formData.merchant?.name ?? "", // Correctly access the merchantName
            storeAssociation: formData.merchant?.storeAssociation ?? "",
            // branch: formData.branch ? formData.branch.code ?? "" : "",
            deviceType: formData.deviceType ? formData.deviceType.code ?? "" : "",
            paymentAgent: formData.paymentAgent ? formData.paymentAgent.code ?? null : null,
            assignedTo: formData.user ? formData.user.username ?? "" : "",
            network: formData.network ?? "",
            location: formData.location ?? '',
            pandateEntryMode: formData.pandateEntryMode ?? '',
            currency: JSON.stringify(formData.currency) ?? "",
            posterminalType: formData.posterminalType ?? '',
            bancnetAddress: formData.bancnetAddress ?? '',
            bancnetFixedFee: formData.bancnetFixedFee ?? null,
            bancnetMerchantSettlementBankCode: formData.bancnetMerchantSettlementBankCode ?? '',
            bancnetTerminalOwner: formData.bancnetTerminalOwner ?? '',
            bancnetTerminalCode: formData.bancnetTerminalCode ?? '',
            maximumAmount: formData.maximumAmount ?? null,
            pinEntryCapability: formData.pinEntryCapability ?? '',
            posTerminalEntryCapability: formData.posTerminalEntryCapability ?? '',
            posConditionCode: formData.posConditionCode ?? '',
            bancnetConvenienceFee: formData.bancnetConvenienceFee ?? null,
            simNetwork: formData.simNetwork ?? '',
            simCardNumber: formData.simCardNumber ?? '',
            dateInstalled: formData.dateInstalled ? (formData.dateInstalled = undefined ? null : formData.dateInstalled) : null,
            installedBy: formData.installedBy ?? '',
            ipAddress: formData.ipAddress ?? '',
            status: formData.status ?? '',
            emvContact: formData.emvContact ?? "",
            cardNetworkUsed: formData.cardNetworkUsed ?? '',
            motopaymentIndicator: formData.motopaymentIndicator ?? '',
            unattendedAcceptanceTerminalIndicator: formData.unattendedAcceptanceTerminalIndicator ?? '',
            cardHolderidmethod: formData.cardHolderidmethod ?? '',
            serviceDevelopmentField: formData.serviceDevelopmentField ?? '',

            password: formData.terminalPassword ?? '',
            terminalMonitoring: formData.terminalMonitoring ?? '',
            autoSettlement: formData.autoSettlementFlag,
            autoSettlementTime: formData.autoSettlementTime ?? '',
            terminalPort: formData.terminalPortNumber ?? '',
            printSaleReceipt: formData.printSaleReceipt,

            enableVoid: formData.enableVoid,
            enableBalanceInquiry: formData.enableBalanceInquiry,
            enableCashout: formData.enableCashout,

            mode: formData.terminalMode ?? '',
            mac: formData.terminalMacAddress ?? '',
            // merchants: formData.merchantAssociation,
            batchIndex: formData.batchIndex ?? '',
            batchLimit: formData.batchLimit ?? '',
            batchNumber: formData.batchNumber ?? '',
            stan: formData.stan ?? '',
            cardSchemes: JSON.stringify(this.filterCardScheme(formData.cardScheme)) ?? {},
            emvs: JSON.stringify(this.filterEmv(formData.emv)) ?? {},

            isEnabled: formData.isEnabled
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error.response.data.message };
        }
    },

    filterCardScheme(cardScheme) {
        var value = cardScheme
            .filter(item => item.cardSchemeId !== '')
            .map(item => {
                // const { cardSchemeIndex, cardSchemeId } = item;
                const { cardSchemeId } = item;
                return {
                    // cardSchemeIndex: cardSchemeIndex,
                    cardSchemeId: cardSchemeId,
                };
            });
        console.log(value)
        return value
    },
    filterEmv(emv) {
        var value = emv
            .filter(item => item.schemeReference !== '')
            .map(item => {
                // const { emvId, schemeReference } = item;
                const { schemeReference } = item;
                return {
                    // emvId: emvId,
                    schemeReference: schemeReference,
                };
            });
        console.log(value)
        return value
    },

    async updateTicket(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/ticket/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/ticket/update`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            code: formData.code,
            issue: formData.issue,
            terminalId: formData.terminalId,
            terminalId: formData.terminalId ? formData.terminalId.terminalId ?? "" : "",
            reportedBy: formData.reportedBy ? formData.reportedBy.username ?? "" : "",
            assignedTo: formData.assignedTo ? formData.assignedTo.username ?? "" : "",
            status: formData.status,
            action: formData.action,
            partsReplaced: formData.partsReplaced,
            serviceSchedule: formData.serviceSchedule,
            dateResolved: formData.dateResolved,
            problem: formData.problem,
            ecodAction: formData.ecodAction,
            remarks: formData.remarks,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async updateStoreIp(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/storeIp/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/ticket/update`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: formData.id,
            store: formData.store,
            ipAddress: formData.ipAddress,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async updateMerchantCommission(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchantCommission/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/merchantCommission/update`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: formData.id ?? null,
            merchantId: formData.merchant ? formData.merchant.id ?? null : null,
            productName: formData.productName ?? '',
            percentCommission: formData.percentCommission ?? null,
            fixedCommission: formData.fixedCommission ?? null,
            itPercentCommission: formData.providerPercentCommission ?? null,
            itFixedCommission: formData.providerFixedCommission ?? null,
            applicationDate: formData.applicationDate ?? null,

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async disableStoreIp(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/storeIp/disable`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/ticket/disable`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: formData.id,
            store: formData.store,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async enableStoreIp(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/storeIp/enable`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/ticket/enable`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: formData.id,
            store: formData.store,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
      * Get list of TDM Branches
      */
    async getAllBranches() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/branch/listBranches`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/branch/listBranches`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of branches." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getBranches(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/searchBranch`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/searchBranch`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            code: query.code ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of branches." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getStoreIps(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/storeIp/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/storeIp/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            store: query.store ?? '',
            ipAddress: query.ipAddress ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of store Ips." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getMerchantCommissions(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchantCommission/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/merchantCommission/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            merchantId: query.merchant ? query.merchant.id ?? null : null,
            productName: query.productName ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of store Ips." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllDeviceTypes() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/deviceType/listDeviceTypes`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/deviceType/listDeviceTypes`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of all device types." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getDeviceTypes(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/deviceType/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/deviceType/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            code: query.code ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of device types." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async testDownloadReport(reportType) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/report/download`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/report/download`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`
            },
            responseType: 'blob',
            timeout: 120000,
        }

        if (reportType == null || reportType == '') {
            reportType = 'CSV'
        }
        var fileName = 'terminals.' + reportType.toLowerCase()
        const body = {
            format: reportType,
            fileLocation: `${fileName}`
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error downloading report." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getPaymentAgents(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/paymentAgent/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/paymentAgent/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            code: query.code ?? '',
            name: query.name ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payment agents." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllPaymentAgents() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/paymentAgent/listPaymentAgents`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/paymentAgent/listPaymentAgents`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of all payment agents." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllTerminals() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/terminal/listTerminals`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/terminal/listTerminals`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log('getAllTerminals: ', response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of all terminals." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createBranch(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/registerBranch`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/registerBranch`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            code: parameters.code,
            name: parameters.name,
            address: parameters.address,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating branch." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createDeviceType(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/deviceType/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/deviceType/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            code: parameters.code,
            name: parameters.name,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating device type." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createTicket(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/ticket/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/ticket/create`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            code: formData.code,
            issue: formData.issue,
            terminalId: formData.terminalId,
            terminalId: formData.terminalId ? formData.terminalId.terminalId ?? "" : "",
            reportedBy: formData.reportedBy ? formData.reportedBy.username ?? "" : "",
            assignedTo: formData.assignedTo ? formData.assignedTo.username ?? "" : "",
            status: formData.status,
            action: formData.action,
            partsReplaced: formData.partsReplaced,
            serviceSchedule: formData.serviceSchedule,
            dateResolved: formData.dateResolved,
            problem: formData.problem,
            ecodAction: formData.ecodAction,
            remarks: formData.remarks,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async createStoreIp(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/storeIp/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/storeIp/create`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            store: formData.store,
            ipAddress: formData.ipAddress
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async createMerchantCommission(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchantCommission/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/merchantCommission/create`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantId: formData.merchant ? formData.merchant.id ?? null : null,
            productName: formData.productName ?? '',
            percentCommission: formData.percentCommission ?? null,
            fixedCommission: formData.fixedCommission ?? null,
            itPercentCommission: formData.providerPercentCommission ?? null,
            itFixedCommission: formData.providerFixedCommission ?? null,
            applicationDate: formData.applicationDate ?? null,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async createPaymentAgent(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/paymentAgent/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/paymentAgent/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            code: parameters.code,
            name: parameters.name,
            mobileNumber: parameters.mobileNumber,
            email: parameters.email,
            isEnabled: parameters.isEnabled,
            merchants: parameters.merchants,
            remarks: parameters.remarks,
            qrEnabled: parameters.qrEnabled,
            baseUrl: parameters.baseUrl,
            userName: parameters.userName,
            passWord: parameters.passWord
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating payment agent." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateBranch(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/updateBranch`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/updateBranch`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            code: parameters.code,
            name: parameters.name,
            address: parameters.address
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating branch." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateDeviceType(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/deviceType/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/deviceType/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            code: parameters.code,
            name: parameters.name,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating device type." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updatePaymentAgent(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/paymentAgent/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/paymentAgent/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            code: parameters.code,
            name: parameters.name,
            mobileNumber: parameters.mobileNumber,
            email: parameters.email,
            isEnabled: parameters.isEnabled,
            merchants: parameters.merchants,
            remarks: parameters.remarks,
            qrEnabled: parameters.qrEnabled,
            baseUrl: parameters.baseUrl,
            userName: parameters.userName,
            passWord: parameters.passWord
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating payment agent." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**
     * Get list of TDM Users
     */
    async getAllAssignedToUsers() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/user/listAssignedToUsers`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/user/listAssignedToUsers`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            },
            timeout: 200000
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of roles." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**
    * Get list of TDM Merchants
    */
    async getMerchants() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/merchant/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/merchant/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**
     * Get unmatched store count totals daily
     */
    async getTopUnmatchedStoresCount() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/dashboard/topUnmatchedStores`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/dashboard/topUnmatchedStores`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            limit: 10,
            type: 'count'
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string;
                 * statusCode string; http status code
                 * transactionTotalsPerType - JSON object containing:
                 * EXPIRED - JSON object containing:
                 * percentage: double; number of percentage of expired transactions/total transactions of the day
                 * count: int; number of expired transactions of the day
                 * PAID - JSON object containing:
                 * percentage: double; number of percentage of paid transactions/total transactions of the day
                 * count: int; number of paid transactions of the day
                 * PENDING - JSON object containing:
                 * percentage: double; number of percentage of pending transactions/total transactions of the day
                 * count: int; number of pending transactions of the day
                 * POSTED - JSON object containing:
                 * percentage: double; number of percentage of posted transactions/total transactions of the day
                 * count: int; number of posted transactions of the day
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of stores." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
     * Get unmatched store amount totals daily
     */
    async getTopUnmatchedStoresAmount() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/dashboard/topUnmatchedStores`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/dashboard/topUnmatchedStores`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            limit: 10,
            type: 'amount'
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string;
                 * statusCode string; http status code
                 * transactionTotalsPerType - JSON object containing:
                 * EXPIRED - JSON object containing:
                 * percentage: double; number of percentage of expired transactions/total transactions of the day
                 * count: int; number of expired transactions of the day
                 * PAID - JSON object containing:
                 * percentage: double; number of percentage of paid transactions/total transactions of the day
                 * count: int; number of paid transactions of the day
                 * PENDING - JSON object containing:
                 * percentage: double; number of percentage of pending transactions/total transactions of the day
                 * count: int; number of pending transactions of the day
                 * POSTED - JSON object containing:
                 * percentage: double; number of percentage of posted transactions/total transactions of the day
                 * count: int; number of posted transactions of the day
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of stores." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
     * Get details of a specific transaction
     */
    async getTransactionDetails(transactionId) {

    },

    /**
     * Filter list of transactions based on the parameters
     * parameters: JSON object containing:
     * offset: int or empty string;
     * limit: int or empty string;
     * payId: string or empty string;
     * merchantId: string or empty string;
     * merchantRef: string or empty string;
     * storeId: string or empty string;
     * storeName: string or empty string;
     * posReference: string or empty string;
     * dateFrom: datestring or empty string;
     * dateTo: datestring or empty string;
     */
    async getUnmatchedTransaction(offset, limit, parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/transaction/searchUnmatched`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/transaction/searchUnmatched`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit,
            payId: parameters.payId,
            merchantId: parameters.merchantId,
            merchantRef: parameters.merchantRef,
            storeId: parameters.storeId,
            storeName: parameters.storeName,
            posReference: parameters.posReference,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
     * Filter list of transactions based on the parameters
     * parameters: JSON object containing:
     * offset: int or empty string;
     * limit: int or empty string;
     * payId: string or empty string;
     * merchantId: string or empty string;
     * merchantRef: string or empty string;
     * storeId: string or empty string;
     * storeName: string or empty string;
     * posReference: string or empty string;
     * dateFrom: datestring or empty string;
     * dateTo: datestring or empty string;
     */
    async getTransactions(offset, limit, parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/transaction/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/transaction/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit,
            terminalId: parameters.terminalId,
            merchantId: parameters.merchantId,
            primaryAccountNumber: parameters.primaryAccountNumber,
            acquirerReferenceNumber: parameters.acquirerReferenceNumber,
            retrievalReferenceNumber: parameters.retrievalReferenceNumber,
            systemTraceAuditNumber: parameters.systemTraceAuditNumber,
            transactionType: parameters.transactionType,
            processingCode: parameters.processingCode,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getCashOutTransactions(offset, limit, parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/transaction/searchCashOut`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/transaction/searchCashOut`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit,
            terminalId: parameters.terminalId,
            merchantId: parameters.merchantId,
            primaryAccountNumber: parameters.primaryAccountNumber,
            acquirerReferenceNumber: parameters.acquirerReferenceNumber,
            retrievalReferenceNumber: parameters.retrievalReferenceNumber,
            systemTraceAuditNumber: parameters.systemTraceAuditNumber,
            transactionType: parameters.transactionType,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getAuditLog(offset, limit, parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/auditLog/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/auditLog/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit,
            user: parameters.user,
            controller: parameters.controller,
            action: parameters.action,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getThresholds(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            type: query.type ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllMerchants() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/listMerchants`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/listMerchants`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: 0,
            pageSize: 0,
            name: ''
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllCountries() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/merchant/listCountries`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/merchant/listCountries`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: 0,
            pageSize: 0,
            name: ''
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of countries." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllMerchantCategoryCodes() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/merchant/listMerchantCategoryCodes`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/merchant/listMerchantCategoryCodes`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: 0,
            pageSize: 0,
            name: ''
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchant category codes." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getFilteredMerchants() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/listFiltered`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/listFiltered`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: 0,
            pageSize: 0,
            name: ''
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of filtered merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createThreshold(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            type: parameters.type,
            count: parameters.count,
            amount: parameters.amount,
            remarks: parameters.remarks,
            isEnabled: parameters.isEnabled

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating threshold." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateThreshold(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            type: parameters.type,
            count: parameters.count,
            amount: parameters.amount,
            remarks: parameters.remarks,
            isEnabled: parameters.isEnabled
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating threshold." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateMerchantFilter(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/updateFiltered`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/updateFiltered`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            merchantFilters: parameters.merchants
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating threshold." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getDisabledStores(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/listDisabled`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/listDisabled`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            storeNo: query.storeNo ?? '',
            storeName: query.storeName ?? '',
            dateFrom: query.dateFrom ?? '',
            dateTo: query.dateTo ?? ''
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of disabled stores." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async enableStore(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/threshold/enableStore`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/threshold/enableStore`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            id: parameters.id,
            storeNo: parameters.storeNo,
            storeName: parameters.storeName
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error enabling store." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getStoreEventLog(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/store/searchEventLog`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/store/searchEventLog`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            storeNo: query.storeNo ?? '',
            storeName: query.storeName ?? '',
            remarks: query.remarks ?? '',
            dateCreatedFrom: query.dateFrom ?? '',
            dateCreatedTo: query.dateTo ?? ''
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of stores event logs." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**
     * Filter list of transactions based on the parameters
     * parameters: JSON object containing:
     * offset: int or empty string;
     * limit: int or empty string;
     * payId: string or empty string;
     * merchantId: string or empty string;
     * merchantRef: string or empty string;
     * storeId: string or empty string;
     * storeName: string or empty string;
     * posReference: string or empty string;
     * dateFrom: datestring or empty string;
     * dateTo: datestring or empty string;
     */
    async searchMerchants(offset, limit, parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/merchant/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/merchant/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit,
            name: parameters.name,
            mobileNumber: parameters.mobileNumber,
            email: parameters.email,
            merchantId: parameters.merchantId,
            enabled: parameters.enabled,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async createMerchant(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/merchant/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/merchant/create`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantName: formData.name,
            legalName: formData.legalName ?? "",
            legalForm: formData.legalForm ?? "",
            distributionOfFees: formData.distributionOfFees ?? "0",
            fixedDiscountRate: formData.fixedDiscountRate ?? "0", // visa fixed fee
            discountRateDebit: formData.discountRateDebit ?? "0", // bancnet percentage
            fixedDiscountRateDebit: formData.fixedDiscountRateDebit ?? "0", // bancnet fixed fee
            discountRate: formData.discountRate ?? "0", // visa percentage
            merchantAddress: formData.merchantAddress ?? "",
            tradeName: formData.tradeName ?? "",
            companyName: formData.companyName ?? "",
            clientNumber: formData.clientNumber ?? "",
            primaryContactEmail: formData.primaryContactEmail ?? "",
            primaryContactNumber: formData.primaryContactNumber ?? "",
            secondaryContactEmail: formData.secondaryContactEmail ?? "",
            secondaryContactNumber: formData.secondaryContactNumber ?? "",
            branchName: formData.branchName ?? "",
            branchContactPerson: formData.branchContactPerson ?? "",
            branchContactNumber: formData.branchContactNumber ?? "",
            branchEmailAddress: formData.branchEmailAddress ?? "",
            serviceFee: formData.serviceFee ?? "0",
            fixedFee: formData.fixedFee ?? "0",
            tinNumber: formData.tinNumber ?? "",
            postalCode: formData.postalCode ?? "",
            city: formData.city,
            state: formData.state ?? "",
            province: formData.province ?? "",
            country: formData.country ? formData.country.numCode ?? "" : "",
            comments: formData.comments ?? "",
            merchantVerificationValue: formData.merchantVerificationValue ?? "",
            paymentAgentCode: formData.paymentAgent ? formData.paymentAgent.code ?? null : null,
            merchantCategoryCode: formData.merchantCategoryCode ? formData.merchantCategoryCode.description ?? "" : "",
            enabled: formData.enabled,
            mvv: formData.mvv,
            // acquirerId: formData.acquirerId,
            withholdingTaxRate: formData.withholdingTaxRate ?? "0",
            minimumAmount: formData.minimumAmount ?? "0",
            maximumAmount: formData.maximumAmount ?? "0",
            averageAmount: formData.averageAmount ?? "0",
            holdOutRate: formData.holdOutRate ?? "0",
            passDiscountRate: formData.passDiscountRate,
            discountRate: formData.discountRate ?? "0",
            passFlatFee: formData.passFlatFee,
            bancnetFlatFee: formData.bancnetFlatFee ?? "0",
            cashoutIncome: formData.cashoutIncome ?? "0",
            cashoutShare: formData.cashoutShare ?? "0",
            serviceFeeLabel: formData.serviceFeeLabel ?? "",
            purchaseIdentifierFormat: formData.purchaseIdentifierFormat ?? "",
            isAsymmetricKeyEnabled: formData.isAsymmetricKeyEnabled,
            footer1: formData.footer1,
            footer2: formData.footer2,
            footer3: formData.footer3,
            depositoryBankName: formData.depositoryBankName ?? "",
            depositoryBankAccountName: formData.depositoryBankAccountName ?? "",
            depositoryBankAccount: formData.depositoryBankAccount ?? "",
            depositoryBankNameUSD: formData.depositoryBankNameUSD ?? "",
            depositoryBankAccountNameUSD: formData.depositoryBankAccountNameUSD ?? "",
            depositoryBankAccountUSD: formData.depositoryBankAccountUSD ?? "",
            principalContactPerson: formData.principalContactPerson ?? "",
            alternateContactPerson: formData.alternateContactPerson ?? "",
            telephoneNumber: formData.telephoneNumber ?? "",
            mobileNumber: formData.mobileNumber ?? "",
            faxNumber: formData.faxNumber ?? "",
            website: formData.website ?? "",
            email: formData.email ?? "",
            authFee: formData.authFee ?? "0",
            chargebackFee: formData.chargebackFee ?? "0",
            monthlyAccessFee: formData.monthlyAccessFee ?? "0",
            refundFee: formData.refundFee ?? "0",
            retrievalFee: formData.retrievalFee ?? "0",
            voiceAuthFee: formData.voiceAuthFee ?? "0",
            settlementFee: formData.settlementFee ?? "0",
            merchantId: formData.merchantId,
            merchantCode: formData.merchantCode,
            storeAssociation: formData.storeAssociation,
            expireAfter: formData.expireAfter ?? "2880",
            validatePayconnect: formData.validatePayconnect,
            stip: formData.stip,
            amountEditable: formData.amountEditable,
            merchantLogoUrl: formData.merchantLogoUrl ?? "",
            isMultipleBiller: formData.isMultipleBiller,
            allowWalletPayment: formData.allowWalletPayment,
            isMobileNumberRequired: formData.isMobileNumberRequired,
            hasPriceAdjustment: formData.hasPriceAdjustment,
            endpointUrl: formData.endpointUrl ?? "",
            connectTimeout: formData.connectTimeout ?? "3000",
            readTimeout: formData.readTimeout ?? "10000",
            randomizeRefNum: formData.randomizeRefNum,
            referenceReusable: formData.referenceReusable,
            accountCode: formData.accountCode ?? "",
            branchCode: formData.branchCode ?? "",
            accountUsername: formData.accountUsername ?? "",
            accountUsercode: formData.accountUsercode ?? "",
            accountPassword: formData.accountPassword ?? ""
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                  * Response.data - JSON object and contains:
                  * message - string
                  * statusCode - string; http status code
                  */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                  * Response.data JSON object and contains:
                  * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                  * status - int; http status code; optional response
                  * statusCode - string; http status codel optional response
                  * message - string; always
                  * expired - boolean; optional response
                  */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
              * Exception error
              */
            console.log(error);
            return { error: error };
        }
    },

    async updateMerchant(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/merchant/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/merchant/update`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: formData.id,
            merchantName: formData.name,
            legalName: formData.legalName ?? "",
            legalForm: formData.legalForm ?? "",
            distributionOfFees: formData.distributionOfFees ?? "0",
            fixedDiscountRate: formData.fixedDiscountRate ?? "0", // visa fixed fee
            discountRateDebit: formData.discountRateDebit ?? "0", // bancnet percentage
            fixedDiscountRateDebit: formData.fixedDiscountRateDebit ?? "0", // bancnet fixed fee
            discountRate: formData.discountRate ?? "0", // visa percentage
            merchantAddress: formData.merchantAddress ?? "",
            tradeName: formData.tradeName ?? "",
            companyName: formData.companyName ?? "",
            clientNumber: formData.clientNumber ?? "",
            primaryContactEmail: formData.primaryContactEmail ?? "",
            primaryContactNumber: formData.primaryContactNumber ?? "",
            secondaryContactEmail: formData.secondaryContactEmail ?? "",
            secondaryContactNumber: formData.secondaryContactNumber ?? "",
            branchName: formData.branchName ?? "",
            branchContactPerson: formData.branchContactPerson ?? "",
            branchContactNumber: formData.branchContactNumber ?? "",
            branchEmailAddress: formData.branchEmailAddress ?? "",
            serviceFee: formData.serviceFee ?? "0",
            fixedFee: formData.fixedFee ?? "0",
            tinNumber: formData.tinNumber ?? "",
            postalCode: formData.postalCode ?? "",
            city: formData.city,
            state: formData.state ?? "",
            province: formData.province ?? "",
            country: formData.country ? formData.country.numCode ?? "" : "",
            comments: formData.comments ?? "",
            merchantVerificationValue: formData.merchantVerificationValue ?? "",
            paymentAgentCode: formData.paymentAgent ? formData.paymentAgent.code ?? null : null,
            merchantCategoryCode: formData.merchantCategoryCode ? formData.merchantCategoryCode.description ?? "" : "",
            enabled: formData.enabled,
            mvv: formData.mvv,
            // acquirerId: formData.acquirerId,
            withholdingTaxRate: formData.withholdingTaxRate ?? "0",
            minimumAmount: formData.minimumAmount ?? "0",
            maximumAmount: formData.maximumAmount ?? "0",
            averageAmount: formData.averageAmount ?? "0",
            holdOutRate: formData.holdOutRate ?? "0",
            passDiscountRate: formData.passDiscountRate,
            discountRate: formData.discountRate ?? "0",
            passFlatFee: formData.passFlatFee,
            bancnetFlatFee: formData.bancnetFlatFee ?? "0",
            cashoutIncome: formData.cashoutIncome ?? "0",
            cashoutShare: formData.cashoutShare ?? "0",
            serviceFeeLabel: formData.serviceFeeLabel ?? "",
            purchaseIdentifierFormat: formData.purchaseIdentifierFormat ?? "",
            isAsymmetricKeyEnabled: formData.isAsymmetricKeyEnabled,
            footer1: formData.footer1 ?? "",
            footer2: formData.footer2 ?? "",
            footer3: formData.footer3 ?? "",
            depositoryBankName: formData.depositoryBankName ?? "",
            depositoryBankAccountName: formData.depositoryBankAccountName ?? "",
            depositoryBankAccount: formData.depositoryBankAccount ?? "",
            depositoryBankNameUSD: formData.depositoryBankNameUSD ?? "",
            depositoryBankAccountNameUSD: formData.depositoryBankAccountNameUSD ?? "",
            depositoryBankAccountUSD: formData.depositoryBankAccountUSD ?? "",
            principalContactPerson: formData.principalContactPerson ?? "",
            alternateContactPerson: formData.alternateContactPerson ?? "",
            telephoneNumber: formData.telephoneNumber ?? "",
            mobileNumber: formData.mobileNumber ?? "",
            faxNumber: formData.faxNumber ?? "",
            website: formData.website ?? "",
            email: formData.email ?? "",
            authFee: formData.authFee ?? "0",
            chargebackFee: formData.chargebackFee ?? "0",
            monthlyAccessFee: formData.monthlyAccessFee ?? "0",
            refundFee: formData.refundFee ?? "0",
            retrievalFee: formData.retrievalFee ?? "0",
            voiceAuthFee: formData.voiceAuthFee ?? "0",
            settlementFee: formData.settlementFee ?? "0",
            merchantId: formData.merchantId,
            merchantCode: formData.merchantCode,
            storeAssociation: formData.storeAssociation,
            expireAfter: formData.expireAfter ?? "2880",
            validatePayconnect: formData.validatePayconnect,
            stip: formData.stip,
            amountEditable: formData.amountEditable,
            merchantLogoUrl: formData.merchantLogoUrl ?? "",
            isMultipleBiller: formData.isMultipleBiller,
            allowWalletPayment: formData.allowWalletPayment,
            isMobileNumberRequired: formData.isMobileNumberRequired,
            hasPriceAdjustment: formData.hasPriceAdjustment,
            endpointUrl: formData.endpointUrl ?? "",
            connectTimeout: formData.connectTimeout ?? "3000",
            readTimeout: formData.readTimeout ?? "10000",
            randomizeRefNum: formData.randomizeRefNum,
            referenceReusable: formData.referenceReusable,
            accountCode: formData.accountCode ?? "",
            branchCode: formData.branchCode ?? "",
            accountUsername: formData.accountUsername ?? "",
            accountUsercode: formData.accountUsercode ?? "",
            accountPassword: formData.accountPassword ?? ""
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                  * Response.data - JSON object and contains:
                  * message - string
                  * statusCode - string; http status code
                  */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                  * Response.data JSON object and contains:
                  * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                  * status - int; http status code; optional response
                  * statusCode - string; http status codel optional response
                  * message - string; always
                  * expired - boolean; optional response
                  */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
              * Exception error
              */
            console.log(error);
            return { error: error };
        }
    },

    async getGroupMerchants(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/groupMerchant/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/groupMerchant/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            name: query.name ?? '',
            remarks: query.remarks ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchant groups." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createGroupMerchant(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/groupMerchant/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/createGroupMerchant/create`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            name: formData.name,
            remarks: formData.remarks,
            merchants: formData.merchants,
            users: formData.users,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async updateGroupMerchant(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/groupMerchant/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/groupMerchant/update`
        }
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: formData.id,
            name: formData.name,
            remarks: formData.remarks,
            merchants: formData.merchants,
            users: formData.users,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
     * Get total amounts and transaction count of transactions per day for Admin Users
     */
    async getTransactionsTotal(limit = 10, offset = 0) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/transaction/pastSummary`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `transaction/pastSummary`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * dashboardTotals - JSON array of transaction objects
                 * transaction object contains:
                 * businessDate - datestring; date the transactions are transacted (yyyy-MM-dd HH:mm:ss.S)
                 * lastUpdated - datestring; date the transaction object is last updated (yyyy-MM-dd HH:mm.ss.S)
                 * transactionAmount - int; total amount computed from the list of transactions per day
                 * dataName - string; name to be displayed for the data in the chart
                 * id - int; transaction object identifier
                 * transactionCount - int; total transaction count computed from the list of transactions per day
                 * countAverage - decimal; average of transactionCount
                 * amountAverage - decimal; average of transactionAmount
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    getError(error) {
        if (error && error.response && error.response.data) {
            console.log(error.response)
            if (error.response.data.message) {
                return { error: error.response.data.message + "" };
            } else if (error.response.data.error) {
                return { error: error.response.data.error + "" };
            } else {
                return { error: error.response.data + "" };
            }
        } else {
            return { error: error + "" };
        }
    },

    async updatePassword(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/userManagement/updatePassword`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/userManagement/updatePassword`
        }
        const user = `${formData.user}:${formData.password}:${formData.newPassword}`
        //  console.log(user);
        const config = {
            headers: {
                'Authorization': `${router.app.$keycloak.token}`,
                'user': `${btoa(user)}`,
                'Content-Type': 'application/json'
            }
        }
        const body = ""
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async getDatePasswordUpdate(user) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/userManagement/checkPasswordDate`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/switch/userManagement/checkPasswordDate`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            username: user
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting passwod details." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async searchBills(offset, limit, parameters, transactionStatus) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_BILLING_SYSTEM_PATH}/api/bill/search_filter?page=${offset}&size=${limit}`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/biller/api/bill/search_filter?page=${offset}&size=${limit}`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            accountNumber: parameters.accountNumber,
            billingNumber: parameters.billingNumber,
            accountName: parameters.accountName,
            startIssueDate: parameters.startIssueDate === '' ? "0000-00-00" : parameters.startIssueDate,
            endIssueDate: parameters.endIssueDate === '' ? new Date().toISOString().slice(0, 10) : parameters.endIssueDate,
            startDueDate: parameters.startDueDate === '' ? "0000-00-00" : parameters.startDueDate,
            endDueDate: parameters.endDueDate === '' ? new Date().toISOString().slice(0, 10) : parameters.endDueDate,
            status: transactionStatus,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async uploadFile(apiURL, formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = apiURL
        // if(process.env.VUE_APP_IS_PROXY === "true") {
        //   /**Use when CORS issue persists, and use proxy*/
        //   url = `/tdm/bill/search_filter?page=0&size=10`
        // }
        const config = {
            headers: {
                // Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            const response = await Vue.axios.post(url, formData, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error in Send File." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    async getMerchantCode() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantMapping/getMerchantCodes`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantMapping/getMerchantCodes`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const response = await Vue.axios.get(url, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants code." };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },


    async getMatchedTransactions(matchedTransaction) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/matchedTransaction/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/matchedTransaction/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            },
            timeout: 200000,
        }
        const body = {
            merchantCode: matchedTransaction.merchantCode ?? "",
            payconnectReference: matchedTransaction.payconnectReference ?? "",
            merchantReference: matchedTransaction.merchantReference ?? "",
            dateFrom: matchedTransaction.dateFrom ?? "",
            dateTo: matchedTransaction.dateTo ?? "",
            isSettled: matchedTransaction.isSettled ?? false,
            pageNo: matchedTransaction.pageNo,
            pageSize: matchedTransaction.pageSize
        }
        console.log(body)
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of matched transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    //UNMATCHED TRANSACTION
    async getMerchantTransactions(merchantTransaction) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantTransaction/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantTransaction/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: merchantTransaction.merchantCode ?? "",
            payconnectReference: merchantTransaction.payconnectReference ?? "",
            merchantReference: merchantTransaction.merchantReference ?? "",
            dateFrom: merchantTransaction.dateFrom ?? "",
            dateTo: merchantTransaction.dateTo ?? "",
            pageNo: merchantTransaction.pageNo,
            pageSize: merchantTransaction.pageSize,
            isDeclined: false
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchant transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getDeclinedTransaction(declinedTransaction) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantTransaction/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantTransaction/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: declinedTransaction.merchantCode ?? "",
            payconnectReference: declinedTransaction.payconnectReference ?? "",
            merchantReference: declinedTransaction.merchantReference ?? "",
            dateFrom: declinedTransaction.dateFrom ?? "",
            dateTo: declinedTransaction.dateTo ?? "",
            pageNo: declinedTransaction.pageNo,
            pageSize: declinedTransaction.pageSize,
            isDeclined: true
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchant transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    async getPayconnectTransactions(payconnectTransaction) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/payconnectTransaction/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/payconnectTransaction/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: payconnectTransaction.merchantCode ?? "",
            payconnectReference: payconnectTransaction.payconnectReference ?? "",
            merchantReference: payconnectTransaction.merchantReference ?? "",
            dateFrom: payconnectTransaction.dateFrom ?? "",
            dateTo: payconnectTransaction.dateTo ?? "",
            pageNo: payconnectTransaction.pageNo,
            pageSize: payconnectTransaction.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**
     * Filter list of transactions based on the parameters
     * parameters: JSON object containing:
     * offset: int or empty string;
     * limit: int or empty string;
     * payId: string or empty string;
     * merchantId: string or empty string;
     * merchantRef: string or empty string;
     * storeId: string or empty string;
     * storeName: string or empty string;
     * posReference: string or empty string;
     * dateFrom: datestring or empty string;
     * dateTo: datestring or empty string;
     */
    async getBillerAccounts(offset, limit, parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_BILLING_SYSTEM_URL}/api/account/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/biller/api/account/search`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            accountName: parameters.accountName ?? '',
            number: parameters.number ?? '',
            referenceNumber: parameters.referenceNumber ?? '',
            subscriptionPlan: parameters.subscriptionPlan ?? '',
            billerId: parameters.billerId ?? '',
            statusId: parameters.statusId ?? '',
            dateFrom: parameters.dateFrom ?? '',
            dateTo: parameters.dateTo ?? ''
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return { error: response.data.message };
            } else {
                /**
                 * Return general error
                 */
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    /**
    * Get list of Biller Account Status
    */
    async getStatusList() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_BILLING_SYSTEM_URL}/api/account/listStatus`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/biller/api/account/listStatus`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }

        try {
            const response = await Vue.axios.get(url, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    //PAYMENT AGENT MAPPING
    async getPaymentAgentMerchant(paymentAgentMerchant) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/paymentAgentMapping/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/paymentAgentMapping/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: paymentAgentMerchant.merchantCode ?? "",
            paymentAgentId: paymentAgentMerchant.paymentAgentId ?? "",
            pageNo: paymentAgentMerchant.pageNo,
            pageSize: paymentAgentMerchant.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    async createPaymentAgentMerchant(paymentAgentMerchant) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/paymentAgentMapping/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/paymentAgentMapping/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: paymentAgentMerchant.merchantCode ?? "",
            paymentAgentId: paymentAgentMerchant.paymentAgentId ?? "",
            paymentAgentCode: paymentAgentMerchant.paymentAgentCode ?? ""
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating payment agent." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    async updatePaymentAgentMerchant(paymentAgentMerchant) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/paymentAgentMapping/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/paymentAgentMapping/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: paymentAgentMerchant.id ?? "",
            merchantCode: paymentAgentMerchant.merchantCode ?? "",
            paymentAgentId: paymentAgentMerchant.paymentAgentId ?? "",
            paymentAgentCode: paymentAgentMerchant.paymentAgentCode ?? ""
        }
        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating payment agent." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getMerchantChannel(merchantChannel) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantChannel/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantChannel/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: merchantChannel.merchantCode ?? "",
            merchantId: merchantChannel.merchantId ?? "",
            pageNo: merchantChannel.pageNo,
            pageSize: merchantChannel.pageSize
        }
        console.log(body)
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    async createMerchantChannel(merchantChannel) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantChannel/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantChannel/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: merchantChannel.merchantCode ?? "",
            merchantId: merchantChannel.merchantId ?? ""
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating payment agent." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateMerchantChannel(merchantChannel) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantChannel/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantChannel/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: merchantChannel.id ?? "",
            merchantCode: merchantChannel.merchantCode ?? "",
            merchantId: merchantChannel.merchantId ?? ""
        }
        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating payment agent." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getMerchantParameters(merchantChannel) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantMapping/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantMapping/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: merchantChannel.merchantCode ?? "",
            pageNo: merchantChannel.pageNo,
            pageSize: merchantChannel.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createMerchantParameters(merchantParameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantMapping/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantMapping/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: merchantParameters.merchantCode ?? "",
            payconnectReferenceMap: merchantParameters.payconnectReferenceMap ?? "",
            merchantReferenceMap: merchantParameters.merchantReferenceMap ?? "",
            amountMap: merchantParameters.amountMap ?? "",
            transactionDateMap: merchantParameters.transactionDateMap ?? "",
            transactionDateFormat: merchantParameters.transactionDateFormat ?? "",
            typeMap: merchantParameters.typeMap ?? "",
            typeCondition: merchantParameters.typeCondition ?? "",
            statusMap: merchantParameters.statusMap ?? "",
            statusCondition: merchantParameters.statusCondition ?? "",
            externalReferenceMap: merchantParameters.externalReferenceMap ?? "",
            unmatchedRetryDays: merchantParameters.unmatchedRetryDays ?? ""
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateMerchantParameters(merchantParameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantMapping/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantMapping/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: merchantParameters.id ?? "",
            merchantCode: merchantParameters.merchantCode ?? "",
            payconnectReferenceMap: merchantParameters.payconnectReferenceMap ?? "",
            merchantReferenceMap: merchantParameters.merchantReferenceMap ?? "",
            amountMap: merchantParameters.amountMap ?? "",
            transactionDateMap: merchantParameters.transactionDateMap ?? "",
            transactionDateFormat: merchantParameters.transactionDateFormat ?? "",
            typeMap: merchantParameters.typeMap ?? "",
            typeCondition: merchantParameters.typeCondition ?? "",
            statusMap: merchantParameters.statusMap ?? "",
            statusCondition: merchantParameters.statusCondition ?? "",
            externalReferenceMap: merchantParameters.externalReferenceMap ?? "",
            unmatchedRetryDays: merchantParameters.unmatchedRetryDays ?? ""
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    async getSettlementBatch(settlementValue, batchStatus) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/settlement/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/settlement/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: settlementValue.merchantCode ?? "",
            settlementReference: settlementValue.settlementReference ?? "",
            settlementStatus: batchStatus,
            dateFrom: settlementValue.dateFrom ?? "",
            dateTo: settlementValue.dateTo ?? "",
            pageNo: settlementValue.pageNo,
            pageSize: settlementValue.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateSettlementBatchForApproval(settlementBatch) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/settlement/preapproveAsync`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/settlement/preapproveAsync`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: settlementBatch.merchantCode,
            dateFrom: settlementBatch.dateFrom,
            dateTo: settlementBatch.dateTo,
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateSettlementBatchToApprove(settlementBatch) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/settlement/approve`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/settlement/approve`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: settlementBatch.id,
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateSettlementBatchToSettle(settlementBatch) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/settlement/settle`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/settlement/settle`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: settlementBatch.id,
            bank: settlementBatch.bank,
            bankAccountNumber: settlementBatch.bankAccountNumber,
            bankReference: settlementBatch.bankReference,
            bankTransactionDate: settlementBatch.bankTransactionDate
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async exportSettlementToCSVorPDF(toDownload, format) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/settlement/export`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/settlement/export`
        }

        const config = {
            responseType: 'arraybuffer',
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: toDownload.id,
            format: format
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                if (format === "csv") {
                    link.setAttribute('download', `${toDownload.settlementReference}.csv`);
                } else {
                    link.setAttribute('download', `${toDownload.settlementReference}.pdf`);
                }
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**
    * Get list of Biller Account Status
    */
    async getBillersList() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_BILLING_SYSTEM_URL}/api/bill/getBillers`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/biller/api/bill/getBillers`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }

        try {
            const response = await Vue.axios.get(url, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async uploadFileMerchant(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/v1/uploadMerchantCsvAsync`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/v1/uploadMerchantCsvAsync`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            const response = await Vue.axios.post(url, formData, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error in Send Sale." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getUploadedFileList(uploadedFileList) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantFileUploadHistory/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantFileUploadHistory/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            merchantCode: uploadedFileList.merchantCode ?? "",
            filename: uploadedFileList.filename ?? "",
            fileUploadHistoryId: uploadedFileList.fileUploadHistoryId ?? "",
            dateFrom: uploadedFileList.dateFrom ?? "",
            dateTo: uploadedFileList.dateTo ?? "",
            pageNo: uploadedFileList.pageNo,
            pageSize: uploadedFileList.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of matched transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async exportMerchantFileUpload(toDownload) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantFileUploadHistory/export`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantFileUploadHistory/export`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: toDownload.id
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${toDownload.originalFilename}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateUnmatchedToDecline(unmatched) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantTransaction/decline`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantTransaction/decline`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            id: unmatched.id,
            name: unmatched.name,
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getBankList(bankList) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bank/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bank/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            code: bankList.code ?? "",
            pageNo: bankList.pageNo,
            pageSize: bankList.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createBank(bankCreated) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bank/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bank/create`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            code: bankCreated.code ?? "",
            name: bankCreated.name ?? ""
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateBank(bankUpdated) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bank/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bank/update`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            id: bankUpdated.id ?? "",
            code: bankUpdated.code ?? "",
            name: bankUpdated.name ?? ""
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getBankCodes() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bank/getBanks`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bank/getBanks`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const response = await Vue.axios.get(url, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants code." };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },

    async getBankAccountList(bankAccountList) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bankAccount/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bankAccount/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            bank: bankAccountList.bank ?? "",
            accountNumber: bankAccountList.accountNumber ?? "",
            accountName: bankAccountList.accountName ?? "",
            pageNo: bankAccountList.pageNo,
            pageSize: bankAccountList.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createBankAccount(bankAccountCreated) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bankAccount/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bankAccount/create`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            bank: bankAccountCreated.bank,
            accountName: bankAccountCreated.accountName,
            accountNumber: bankAccountCreated.accountNumber
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateBankAccount(bankAccountUpdated) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bankAccount/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bankAccount/update`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            id: bankAccountUpdated.id,
            bank: bankAccountUpdated.bank,
            accountName: bankAccountUpdated.accountName,
            accountNumber: bankAccountUpdated.accountNumber
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async exportMatchedTransaction(merchantCode, dateFrom, dateTo) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/matchedTransaction/export`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/matchedTransaction/export`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            },
            timeout: 200000,
        }
        const body = {
            merchantCode: merchantCode,
            dateFrom: dateFrom,
            dateTo: dateTo
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${merchantCode}_${dateFrom}_${dateTo}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of settlement transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async exportMerchantTransaction(merchantCode, dateFrom, dateTo, payconnectReference, merchantReference) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/merchantTransaction/export`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/merchantTransaction/export`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            },
            timeout: 200000,
        }
        const body = {
            merchantCode: merchantCode,
            dateFrom: dateFrom,
            dateTo: dateTo,
            payconnectReference: payconnectReference,
            merchantReference: merchantReference
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `UNMATCHED_${merchantCode}_MC_${dateFrom}_${dateTo}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchant transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async exportPayconnectTransaction(merchantCode, dateFrom, dateTo, payconnectReference, merchantReference) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/payconnectTransaction/export`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/payconnectTransaction/export`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            },
            timeout: 200000,
        }
        const body = {
            merchantCode: merchantCode,
            dateFrom: dateFrom,
            dateTo: dateTo,
            payconnectReference: payconnectReference,
            merchantReference: merchantReference
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `UNMATCHED_${merchantCode}_PC_${dateFrom}_${dateTo}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getBankAccounts() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/bankAccount/getBankAccounts`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/bankAccount/getBankAccounts`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const response = await Vue.axios.get(url, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants code." };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },

    async getDeclineReasonList(declineReasonList) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/declineReason/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/declineReason/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        const body = {
            name: declineReasonList.name ?? "",
            description: declineReasonList.description ?? "",
            pageNo: declineReasonList.pageNo,
            pageSize: declineReasonList.pageSize
        }
        console.log(body)
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createDeclineReason(declineReason) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/declineReason/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/declineReason/create`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            name: declineReason.name,
            description: declineReason.description,
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateDeclineReason(declineReason) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/declineReason/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/declineReason/update`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            id: declineReason.id,
            name: declineReason.name,
            description: declineReason.description,
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getDeclineReasons() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_MODULE_URL}/declineReason/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/recon/declineReason/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const response = await Vue.axios.get(url, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants code." };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },

    //wallet credential setting APIs

    async getWalletCredentialList(walletCredentialList) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/config/listWalletTenants`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/config/listWalletTenants`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            code: walletCredentialList.code ?? "",
            displayName: walletCredentialList.displayName ?? "",
            pageNo: walletCredentialList.pageNo,
            pageSize: walletCredentialList.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createWalletCredentials(walletCredentials) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/config/createWalletTenant`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/config/createWalletTenant`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            code: walletCredentials.code ?? "",
            displayName: walletCredentials.displayName ?? "",
            baseUrl: walletCredentials.baseUrl ?? "",
            transactionEndpoint: walletCredentials.transactionEndpoint ?? "",
            accountsEndpoint: walletCredentials.accountsEndpoint ?? "",
            balanceEndpoint: walletCredentials.balanceEndpoint ?? "",
            debitEndpoint: walletCredentials.debitEndpoint ?? "",
            refundEndpoint: walletCredentials.refundEndpoint ?? "",
            isDataDriven: false,
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateWalletCredentials(walletCredentials) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/config/updateWalletTenant`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/config/updateWalletTenant`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            code: walletCredentials.code ?? "",
            displayName: walletCredentials.displayName ?? "",
            debitEndpoint: walletCredentials.debitEndpoint ?? "",
            refundEndpoint: walletCredentials.refundEndpoint ?? "",
            isDataDriven: false
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getWalletAccountList(walletAccountList) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/wallet/searchWalletAccounts`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/wallet/searchWalletAccounts`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            code: walletAccountList.code ?? "",
            displayName: walletAccountList.displayName ?? "",
            pageNo: walletAccountList.pageNo,
            pageSize: walletAccountList.pageSize
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createWalletAccount(WalletAccount) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/config/generate`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/config/generate`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            tenant: WalletAccount.tenant ?? "",
            keycloakRealmId: WalletAccount.keycloakRealmId ?? "",
            username: WalletAccount.username ?? "",
            password: WalletAccount.password ?? "",
            clientId: WalletAccount.clientId ?? "",
            configName: WalletAccount.configName ?? "",
            accountNumber: WalletAccount.accountNumber ?? "",
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateWalletAccount(WalletAccount) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/config/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/config/update`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }

        const body = {
            configName: WalletAccount.configName ?? "",
            accountNumber: WalletAccount.accountNumber ?? "",
        }

        try {
            const response = await Vue.axios.put(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of payconnect transactions." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getWalletAccounts() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/wallet/listWalletAccounts`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/wallet/listWalletAccounts`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const response = await Vue.axios.get(url, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants code." };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },




    async getWalletTenantList() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/config/getWalletTenants`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/config/getWalletTenants`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            }
        }
        try {
            const response = await Vue.axios.get(url, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants code." };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },


    //RECON-THRESHOLD APIs

    //disabledStore.vue
    async getDisabledStoresRecon(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/listDisabled`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/listDisabled`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            storeNo: query.storeNo ?? '',
            storeName: query.storeName ?? '',
            dateFrom: query.dateFrom ?? '',
            dateTo: query.dateTo ?? ''
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of disabled stores." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async enableStoreRecon(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/enableStore`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/enableStore`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            id: parameters.id,
            storeNo: parameters.storeNo,
            storeName: parameters.storeName
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error enabling store." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    //merchantFilter.vue
    async getFilteredMerchantsRecon() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/listFiltered`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/listFiltered`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: 0,
            pageSize: 0,
            name: ''
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of filtered merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateMerchantFilterRecon(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/updateFiltered`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/updateFiltered`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            merchantFilters: parameters.merchants
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating threshold." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllMerchantsRecon() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/listMerchants`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/listMerchants`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: 0,
            pageSize: 0,
            name: ''
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    //StoreEventLog
    async getStoreEventLogRecon(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/store/searchEventLog`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/store/searchEventLog`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            storeNo: query.storeNo ?? '',
            storeName: query.storeName ?? '',
            remarks: query.remarks ?? '',
            dateCreatedFrom: query.dateFrom ?? '',
            dateCreatedTo: query.dateTo ?? ''
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of stores event logs." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    //Threshold Management
    async getThresholdsRecon(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            type: query.type ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of merchants." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createThreshold(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            type: parameters.type,
            count: parameters.count,
            amount: parameters.amount,
            remarks: parameters.remarks,
            isEnabled: parameters.isEnabled

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating threshold." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateThreshold(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_RECON_DASHBOARD_URL}/threshold/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `recondashboard/threshold/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            type: parameters.type,
            count: parameters.count,
            amount: parameters.amount,
            remarks: parameters.remarks,
            isEnabled: parameters.isEnabled
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating threshold." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },


    //mobile top up

    async uploadFileTopupCSV(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_WALLET_MODULE_URL}/api/topup/upload`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/wallet/api/topup/upload`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            const response = await Vue.axios.post(url, formData, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error in uploading CSV." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async downloadVisaCTF(datePicked, dateCreated) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/report/downloadCTF`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/report/downloadCTF`
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            timeout: 200000,
        }
        const body = {
            date: dateCreated,
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${datePicked}.ctf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "No transaction found" };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async uploadVisaCTF(formData) {
        let url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/report/uploadCTF`;
        if (process.env.VUE_APP_IS_PROXY === "true") {
            url = `/tdm/report/uploadCTF`;
        }

        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        };

        try {
            const response = await Vue.axios.post(url, formData, config);
            if (response && response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'visa-settlement.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
                return downloadUrl;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error in uploading CTF." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error);
        }
    },

    async uploadVisaCTF(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/report/uploadCTF`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/report/uploadCTF`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'multipart/form-data'
            },

            responseType: 'blob'
        }
        try {
            const response = await Vue.axios.post(url, formData, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `visa-settlement.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error in uploading CTF." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createCurrency(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/currency/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/currency/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            country: parameters.countryName,
            code: parameters.code,
            name: parameters.name,
            numCode: parameters.numCode,
            symbol: parameters.symbol,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log("RES", response)
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating currency." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateCurrency(parameters) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/currency/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/currency/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            country: parameters.countryName,
            code: parameters.code,
            name: parameters.name,
            numCode: parameters.numCode,
            symbol: parameters.symbol,
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating currency." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },



    async getAllCurrencies() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/currency/listCurrencies`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/currency/listCurrencies`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log("CURR", response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of currencies." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async searchCurrencies(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/currency/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/currency/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            country: query.country ?? '',
            numCode: query.numCode ?? '',
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of currencies." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getEmvs(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/emv/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/emv/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            emvId: query.emvParameterIndex ?? ''
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of emvs." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },



    async createEmv(params) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/emv/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `tdm/emv/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            emvId: params.emvParameterIndex,
            schemeReference: params.schemeReference ?? "",
            issuerReference: params.issuerReference ?? "",
            trmDataPresent: params.trmDataPresent ?? "",
            floorLimit: params.floorLimit ?? "",
            rsThreshold: params.rsThreshold ?? "",
            targetRs: params.targetRs ?? "",
            maxTargetRs: params.maxTargetRs ?? "",
            tacDefault: params.tacDefault ?? "",
            tacDenial: params.tacDenial ?? "",
            tacOnline: params.tacOnline ?? "",
            defaultTDOL: params.defaultTDOL ?? "",
            defaultDDOL: params.defaultDDOL ?? "",
            nextRecord: params.nextRecord ?? "",
            emvCount: params.emvCount ?? "",
            termCountryCode: params.termCountryCode ?? "",
            termCurrencyCode: params.termCurrencyCode ?? "",
            termCapabilities: params.termCapabilities ?? "",
            termAddCapabilities: params.termAddCapabilities ?? "",
            termType: params.termType ?? "",
            mcc: params.mcc ?? "",
            contactlessCvmLimit: params.contactlessCvmLimit ?? ""
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating Emv." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async updateEmv(params) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/emv/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `tdm/emv/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            emvId: params.emvParameterIndex,
            schemeReference: params.schemeReference ?? "",
            issuerReference: params.issuerReference ?? "",
            trmDataPresent: params.trmDataPresent ?? "",
            floorLimit: params.floorLimit ?? "",
            rsThreshold: params.rsThreshold ?? "",
            targetRs: params.targetRs ?? "",
            maxTargetRs: params.maxTargetRs ?? "",
            tacDefault: params.tacDefault ?? "",
            tacDenial: params.tacDenial ?? "",
            tacOnline: params.tacOnline ?? "",
            defaultTDOL: params.defaultTDOL ?? "",
            defaultDDOL: params.defaultDDOL ?? "",
            nextRecord: params.nextRecord ?? "",
            emvCount: params.emvCount ?? "",
            termCountryCode: params.termCountryCode ?? "",
            termCurrencyCode: params.termCurrencyCode ?? "",
            termCapabilities: params.termCapabilities ?? "",
            termAddCapabilities: params.termAddCapabilities ?? "",
            termType: params.termType ?? "",
            mcc: params.mcc ?? "",
            contactlessCvmLimit: params.contactlessCvmLimit ?? ""
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating emv." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllEmvs() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/emv/listEmvs`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/emv/listEmvs`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of Emvs." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllCardSchemeIds() {
      /**Use when CORS issue is resolved, directly communicate to server without proxy*/
      var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/cardSchemeList`
      if(process.env.VUE_APP_IS_PROXY === "true") {
        /**Use when CORS issue persists, and use proxy*/
        url = `/tdm/cardScheme/cardSchemeList`
      }
      const config = {
        headers: {
          Authorization: `${router.app.$keycloak.token}`
        }
      }
      const body = {
      }

      try {
        const response = await Vue.axios.get(url, body, config);
        console.log(response);
        if (response && response.data && response.status == 200) {
          return response.data;
        } else if (response && response.data && response.data.message) {
          return { error: response.data.message };
        } else {
          return { error: "Sorry. Error getting list of Emvs." };
        }
      } catch (error) {
        console.log(error);
        return this.getError(error)
      }
    },

    async getSchemes(offset, limit, query) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/cardScheme/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            cardSchemeId: query.cardSchemeId ?? ''

        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of Card Schemes." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },



    async createCardScheme(params) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `tdm/cardScheme/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            cardSchemeIndex: params.cardSchemeIndex,
            cardSchemeId: params.cardSchemeId,
            eptRecord: params.eptRecord,
            rid: params.rid ?? "",
            acquirerId: params.acquirer.acquirerId,
            capkIndex1: params.capkIndex1 ?? "",
            capkFile1: params.capkFile1 ?? "",
            capkExptDate1: params.capkExptDate1 ?? "",
            capkIndex2: params.capkIndex2 ?? "",
            capkFile2: params.capkFile2 ?? "",
            capkExptDate2: params.capkExptDate2 ?? "",
            capkIndex3: params.capkIndex3 ?? "",
            capkFile3: params.capkFile3 ?? "",
            capkExptDate3: params.capkExptDate3 ?? "",
            capkIndex4: params.capkIndex4 ?? "",
            capkFile4: params.capkFile4 ?? "",
            capkExptDate4: params.capkExptDate4 ?? "",
            capkIndex5: params.capkIndex5 ?? "",
            capkFile5: params.capkFile5 ?? "",
            capkExptDate5: params.capkExptDate5 ?? "",
            capkIndex6: params.capkIndex6 ?? "",
            capkFile6: params.capkFile6 ?? "",
            capkExptDate6: params.capkExptDate6 ?? "",
            capkIndex7: params.capkIndex7 ?? "",
            capkFile7: params.capkFile7 ?? "",
            capkExptDate7: params.capkExptDate7 ?? "",
            capkIndex8: params.capkIndex8 ?? "",
            capkFile8: params.capkFile8 ?? "",
            capkExptDate8: params.capkExptDate8 ?? "",
            capkIndex9: params.capkIndex9 ?? "",
            capkFile9: params.capkFile9 ?? "",
            capkExptDate9: params.capkExptDate9 ?? "",
            capkIndex10: params.capkIndex10 ?? "",
            capkFile10: params.capkFile10 ?? "",
            capkExptDate10: params.capkExptDate10 ?? "",
            supportedAid1: params.supportedAid1 ?? "",
            partialNameFlag1: params.partialNameFlag1 ?? 0,
            termAvn1: params.termAvn1 ?? "",
            secondTermAvn1: params.secondTermAvn1 ?? "",
            supportedAid2: params.supportedAid2 ?? "",
            partialNameFlag2: params.partialNameFlag2 ?? 0,
            termAvn2: params.termAvn2 ?? "",
            secondTermAvn2: params.secondTermAvn2 ?? "",
            supportedAid3: params.supportedAid3 ?? "",
            partialNameFlag3: params.partialNameFlag3 ?? 0,
            termAvn3: params.termAvn3 ?? "",
            secondTermAvn3: params.secondTermAvn3 ?? "",
            supportedAid4: params.supportedAid4 ?? "",
            partialNameFlag4: params.partialNameFlag4 ?? 0,
            termAvn4: params.termAvn4 ?? "",
            secondTermAvn4: params.secondTermAvn4 ?? "",
            supportedAid5: params.supportedAid5 ?? "",
            partialNameFlag5: params.partialNameFlag5 ?? 0,
            termAvn5: params.termAvn5 ?? "",
            secondTermAvn5: params.secondTermAvn5 ?? "",
            supportedAid6: params.supportedAid6 ?? "",
            partialNameFlag6: params.partialNameFlag6 ?? 0,
            termAvn6: params.termAvn6 ?? "",
            secondTermAvn6: params.secondTermAvn6 ?? "",
            supportedAid7: params.supportedAid7 ?? "",
            partialNameFlag7: params.partialNameFlag7 ?? 0,
            termAvn7: params.termAvn7 ?? "",
            secondTermAvn7: params.secondTermAvn7 ?? "",
            supportedAid8: params.supportedAid8 ?? "",
            partialNameFlag8: params.partialNameFlag8 ?? 0,
            termAvn8: params.termAvn8 ?? "",
            secondTermAvn8: params.secondTermAvn8 ?? "",
            supportedAid9: params.supportedAid9 ?? "",
            partialNameFlag9: params.partialNameFlag9 ?? 0,
            termAvn9: params.termAvn9 ?? "",
            secondTermAvn9: params.secondTermAvn9 ?? "",
            supportedAid10: params.supportedAid10 ?? "",
            partialNameFlag10: params.partialNameFlag10 ?? 0,
            termAvn10: params.termAvn10 ?? "",
            secondTermAvn10: params.secondTermAvn10 ?? "",
            type: params.type ?? ""
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 201) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error creating Card Scheme." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createCardSchemeId(params) {
      var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/createCardSchemeList`
      if(process.env.VUE_APP_IS_PROXY === "true") {
        /**Use when CORS issue persists, and use proxy*/
        url = `tdm/cardScheme/createCardSchemeList`
      }
      const config = {
        headers: {
          Authorization: `${router.app.$keycloak.token}`
        }
      }
      const body = {
        id: params.id,
        // cardSchemeCode: params.cardSchemeCode,
        name: params.name
      }
      try {
        const response = await Vue.axios.post(url, body, config);
        console.log(response);
        if (response && response.data && response.status == 201) {
          return response.data;
        } else if (response && response.data && response.data.message) {
          return { error: response.data.message };
        } else {
          return { error: "Sorry. Error creating Card Scheme." };
        }
      } catch (error) {
        console.log(error);
        return this.getError(error)
      }
    },

    async updateCardScheme(params) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `tdm/cardScheme/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            cardSchemeIndex: params.cardSchemeIndex,
            cardSchemeId: params.cardSchemeId,
            eptRecord: params.eptRecord,
            acquirerId: params.acquirer.acquirerId,
            rid: params.rid ?? "",
            capkIndex1: params.capkIndex1 ?? "",
            capkFile1: params.capkFile1 ?? "",
            capkExptDate1: params.capkExptDate1 ?? "",
            capkIndex2: params.capkIndex2 ?? "",
            capkFile2: params.capkFile2 ?? "",
            capkExptDate2: params.capkExptDate2 ?? "",
            capkIndex3: params.capkIndex3 ?? "",
            capkFile3: params.capkFile3 ?? "",
            capkExptDate3: params.capkExptDate3 ?? "",
            capkIndex4: params.capkIndex4 ?? "",
            capkFile4: params.capkFile4 ?? "",
            capkExptDate4: params.capkExptDate4 ?? "",
            capkIndex5: params.capkIndex5 ?? "",
            capkFile5: params.capkFile5 ?? "",
            capkExptDate5: params.capkExptDate5 ?? "",
            capkIndex6: params.capkIndex6 ?? "",
            capkFile6: params.capkFile6 ?? "",
            capkExptDate6: params.capkExptDate6 ?? "",
            capkIndex7: params.capkIndex7 ?? "",
            capkFile7: params.capkFile7 ?? "",
            capkExptDate7: params.capkExptDate7 ?? "",
            capkIndex8: params.capkIndex8 ?? "",
            capkFile8: params.capkFile8 ?? "",
            capkExptDate8: params.capkExptDate8 ?? "",
            capkIndex9: params.capkIndex9 ?? "",
            capkFile9: params.capkFile9 ?? "",
            capkExptDate9: params.capkExptDate9 ?? "",
            capkIndex10: params.capkIndex10 ?? "",
            capkFile10: params.capkFile10 ?? "",
            capkExptDate10: params.capkExptDate10 ?? "",
            supportedAid1: params.supportedAid1 ?? "",
            partialNameFlag1: params.partialNameFlag1 ?? 0,
            termAvn1: params.termAvn1 ?? "",
            secondTermAvn1: params.secondTermAvn1 ?? "",
            supportedAid2: params.supportedAid2 ?? "",
            partialNameFlag2: params.partialNameFlag2 ?? 0,
            termAvn2: params.termAvn2 ?? "",
            secondTermAvn2: params.secondTermAvn2 ?? "",
            supportedAid3: params.supportedAid3 ?? "",
            partialNameFlag3: params.partialNameFlag3 ?? 0,
            termAvn3: params.termAvn3 ?? "",
            secondTermAvn3: params.secondTermAvn3 ?? "",
            supportedAid4: params.supportedAid4 ?? "",
            partialNameFlag4: params.partialNameFlag4 ?? 0,
            termAvn4: params.termAvn4 ?? "",
            secondTermAvn4: params.secondTermAvn4 ?? "",
            supportedAid5: params.supportedAid5 ?? "",
            partialNameFlag5: params.partialNameFlag5 ?? 0,
            termAvn5: params.termAvn5 ?? "",
            secondTermAvn5: params.secondTermAvn5 ?? "",
            supportedAid6: params.supportedAid6 ?? "",
            partialNameFlag6: params.partialNameFlag6 ?? 0,
            termAvn6: params.termAvn6 ?? "",
            secondTermAvn6: params.secondTermAvn6 ?? "",
            supportedAid7: params.supportedAid7 ?? "",
            partialNameFlag7: params.partialNameFlag7 ?? 0,
            termAvn7: params.termAvn7 ?? "",
            secondTermAvn7: params.secondTermAvn7 ?? "",
            supportedAid8: params.supportedAid8 ?? "",
            partialNameFlag8: params.partialNameFlag8 ?? 0,
            termAvn8: params.termAvn8 ?? "",
            secondTermAvn8: params.secondTermAvn8 ?? "",
            supportedAid9: params.supportedAid9 ?? "",
            partialNameFlag9: params.partialNameFlag9 ?? 0,
            termAvn9: params.termAvn9 ?? "",
            secondTermAvn9: params.secondTermAvn9 ?? "",
            supportedAid10: params.supportedAid10 ?? "",
            partialNameFlag10: params.partialNameFlag10 ?? 0,
            termAvn10: params.termAvn10 ?? "",
            secondTermAvn10: params.secondTermAvn10 ?? "",
            type: params.type ?? "",
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error updating emv." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async getAllCardSchemes() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/cardScheme/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of Card Schemes." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },
    async uploadBinRange(formData) {
      /**Use when CORS issue is resolved, directly communicate to server without proxy*/
      var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/saveBINs`
      if(process.env.VUE_APP_IS_PROXY === "true") {
        /**Use when CORS issue persists, and use proxy*/
        url = `/tdm/cardScheme/saveBINs`
      }
      const config = {
        headers: {
          Authorization: `${router.app.$keycloak.token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
      try {
        const response = await Vue.axios.post(url, formData, config);
        console.log(response);
        if (response && response.data && response.status == 200) {
          return response.data;
        } else if (response && response.data && response.data.message) {
          return { error: response.data.message };
        } else {
          return { error: "Sorry. Error in Send File." };
        }
      } catch (error) {
        console.log(error);
        return this.getError(error)
      }
    },
    async getCardDefinitions(offset, limit, formData) {
      console.log("formData", formData)
      /**Use when CORS issue is resolved, directly communicate to server without proxy*/
      var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardDefinition/searchV2`

    //   if(formData.bin === "" && formData.network === "") {
    //     url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardDefinition/listV2`
    //   }

    //   if(process.env.VUE_APP_IS_PROXY === "true") {
    //     /**Use when CORS issue persists, and use proxy*/
    //     url = `/tdm/cardDefinition/searchV2`
    //     if(formData.bin === "") {
    //       url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardDefinition/list`
    //     }
        // }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: offset,
            pageSize: limit,
            bin: formData.bin,
            network: formData.network

        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of Card Definition." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },
    async getVisaBinRecord(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/report/visaBinRecordsSearch`;
        if (formData.bin === "") {
            url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/report/visaBinRecordsList`;
        }
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/report/visaBinRecordsSearch`;
            if (formData.bin === "") {
                url = `/tdm/report/visaBinRecordsList`;
            }
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: ``,
            pageSize: `10`,

            bin: `${formData.bin}`,
        };

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
              return response.data;
            } else if (response && response.data && response.data.message) {
              return { error: response.data.message };
            } else {
              return { error: "Sorry. Error getting list of Visa Bin Range." };
            }
          } catch (error) {
            console.log(error);
            return this.getError(error)
          }
        },
    async getMayaBinRange(formData) {


      /**Use when CORS issue is resolved, directly communicate to server without proxy*/
      var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/binRangeSearch`

      if (formData.bin === "") {
        url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardScheme/binRangeList`
      }
      if(process.env.VUE_APP_IS_PROXY === "true") {
        /**Use when CORS issue persists, and use proxy*/
        url = `/tdm/cardScheme/binRangeSearch`
        if (formData.bin === "") {
          url = `/tdm/cardScheme/binRangeList`
        }
      }
      const config = {
        headers: {
          Authorization: `${router.app.$keycloak.token}`
        }
      }
      const body = {
        pageNo: ``,
        pageSize: `10`,
        bin: `${formData.bin}`
      }

      try {
        const response = await Vue.axios.post(url, body, config);
        console.log(response);
        if (response && response.data && response.status == 200) {
          return response.data;
        } else if (response && response.data && response.data.message) {
          return { error: response.data.message };
        } else {
          return { error: "Sorry. Error getting list of Bin Range." };
        }
      } catch (error) {
        console.log(error);
        return this.getError(error)
      }
    },
    async getAcquirers(formData) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/acquirer/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/acquirer/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            pageNo: ``,
            pageSize: `10`,
            acquirerName: `${formData.acquirerName}`
        }

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of Card Schemes." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },
    async getAcquirerOptions() {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/acquirer/acquirerList`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/acquirer/acquirerList`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }

        try {
            const response = await Vue.axios.get(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            } else {
                return { error: "Sorry. Error getting list of Card Schemes." };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    async createAcquirer(formData) {

        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/acquirer/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/acquirer/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            acquirerId: `${formData.acquirerId}`,
            acquirerName: `${formData.acquirerName}`,
            acquirerCode: `${formData.acquirerCode}`
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 201) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async createCardDefinition(formData) {

        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardDefinition/create`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/cardDefinition/create`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            // bin: `${formData.bin}`,
            low: `${formData.low}`,
            high: `${formData.high}`,
            isSupported: `${formData.isSupported}`,
            issuingInstitution: `${formData.issuingInstitution}`,
            scheme: `${formData.cardScheme}`,
            network: `${formData.network}`,
            type: `${formData.cardType}`
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 201) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async updateAcquirer(formData) {

        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/acquirer/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/acquirer/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            acquirerId: `${formData.acquirerId}`,
            acquirerName: `${formData.acquirerName}`,
            acquirerCode: `${formData.acquirerCode}`
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async updateCardDefinition(formData) {

        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardDefinition/update`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/cardDefinition/update`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
            // bin: `${formData.bin}`,
            id: `${formData.id}`,
            low: `${formData.low}`,
            high: `${formData.high}`,
            isSupported: `${formData.isSupported}`,
            issuingInstitution: `${formData.issuingInstitution}`,
            scheme: `${formData.cardScheme}`,
            network: `${formData.network}`,
            type: `${formData.cardType}`
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http status code
                 */
                return response.data;
            } else if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
                 * status - int; http status code; optional response
                 * statusCode - string; http status codel optional response
                 * message - string; always
                 * expired - boolean; optional response
                 */
                return { error: response.data.message };
            }
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return { error: error };
        }
    },

    async acquirerList() {

        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/acquirer/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/acquirer/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },

    async cardDefinitionList() {

        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/cardDefinition/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/cardDefinition/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`
            }
        }
        const body = {
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            } else if (response && response.data && response.data.message) {
                return { error: response.data.message };
            }
        } catch (error) {
            console.log(error);
            return { error: error };
        }
    },

    async getTransactionHistoryById(offset, limit, parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/history/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/history/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            transactionId: parameters.transactionHistoryId,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return { error: response.data.message };
            }
            return { error: 'Sorry. Error getting details of Transaction History.' };
        } catch (error) {
            console.log(error);
            return { error };
        }
    },


    //settlement list display
    async searchTerminalSettlementById(offset, limit, parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/settlementApi/search`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/settlementApi/search`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            dateFrom: parameters.dateFrom ?? '',
            dateTo: parameters.dateTo ?? '',
            terminalId: parameters.terminalId ?? '',
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return { error: response.data.message };
            }
            return { error: 'Sorry. Error getting details of Settled Transactions.' };
        } catch (error) {
            console.log(error);
            return { error };
        }
    },

    //settled transactions list
    async getSettledTransactionList(offset, limit, parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/settlementApi/list`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `/tdm/settlementApi/list`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            id: parameters.id,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return { error: response.data.message };
            }
            return { error: 'Sorry. Error getting details of Transaction History.' };
        } catch (error) {
            console.log(error);
            return { error };
        }
    },

    async refundCardPayment(params) {
        /**Use when CORS issue is resolved, directly communicate to server without proxy*/
        var url = `${process.env.VUE_APP_TERMINAL_MODULE_URL}/payment/card/void`
        if (process.env.VUE_APP_IS_PROXY === "true") {
            /**Use when CORS issue persists, and use proxy*/
            url = `payment/card/void`
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            }
        }
        const body = {
            merchant: {
                acquiringTerminal: {
                    id: params.id
                }
            },
            transaction: {
                amount: {
                    total: {
                        currency: "PHP",
                        value: params.amount
                    }
                }
            },
            reason: params.reason,
            reasonCode: params.reasonCode,
            trace: {
                batchNo: params.batchNumber,
                traceNo: params.stan
            }
        }
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.status == 200) {
                return response;
            } else if (response && response.data && response.data.message) {
                return { error: response };
            } else {
                return { error: "Sorry. Please Try Again" };
            }
        } catch (error) {
            console.log(error);
            return this.getError(error)
        }
    },

    /**How to use the APIs above
   * Example:
   * import API from '@/api/API'
   * API.getMerchantList().then(response => {
      console.log(response)
    })
   * or------------------------
   * const merchantList = await API.getMerchantList()
   * The function where the above statement should be declared as async then
   */
}
